import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Details, Summary } from 'react-accessible-details';
import pageContent from '../content/six-contracts.yaml';

const SixWaysPage = () => {
  return (
    <Layout>
      <SEO title={pageContent.title} />
      <PageContainer>
        <h2 className="page-title">{pageContent.title}</h2>
        <section className="container">
          <div className="content">
            {pageContent.buttons.map((data, index) => {
              return (
                <Details key={`detail-${index}`}>
                  <Summary>{data.title}</Summary>
                  <span dangerouslySetInnerHTML={{ __html: data.content }} />
                </Details>
              );
            })}
          </div>
        </section>
      </PageContainer>
    </Layout>
  );
};

export const PageContainer = styled.div`
  .page-title {
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 1.375;
    }
  }

  .container {
    max-width: 1184px;
    margin: 0 auto;
  }

  .content {
    background: #fff;
    color: ${constants.contentTextColor};
    padding: 40px 1rem;
    border-radius: 8px;

    @media (max-width: 767.98px) {
      border-radius: 0;
    }
  }
`;

export default SixWaysPage;
